import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
const YoutubeIcon = (props) => {
    return (
      <SvgIcon {...props} viewBox="0 0 56 56">
        {/* Your SVG path goes here */}
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="44" viewBox="0 0 60 44" fill="none">
  <g clip-path="url(#clip0_455_70)">
    <path d="M58.6749 6.86278C58.3307 5.53631 57.6594 4.32702 56.7278 3.35531C55.7961 2.38361 54.6367 1.68339 53.3649 1.3244C48.7088 0 29.9696 0 29.9696 0C29.9696 0 11.2294 0.0400888 6.57333 1.36449C5.30149 1.7235 4.14202 2.42375 3.21038 3.39551C2.27874 4.36726 1.60744 5.5766 1.26333 6.90311C-0.145033 15.5315 -0.691361 28.6792 1.302 36.9624C1.64615 38.2889 2.31747 39.4982 3.2491 40.4699C4.18074 41.4416 5.34019 42.1418 6.612 42.5008C11.2681 43.8252 30.0078 43.8252 30.0078 43.8252C30.0078 43.8252 48.7472 43.8252 53.4031 42.5008C54.6749 42.1419 55.8344 41.4417 56.7661 40.47C57.6978 39.4983 58.3692 38.2889 58.7133 36.9624C60.1988 28.3218 60.6565 15.1822 58.6749 6.86278Z" fill="#FF0000"/>
    <path d="M24.0049 31.3036L39.5505 21.9126L24.0049 12.5215V31.3036Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_455_70">
      <rect width="60" height="44" fill="white"/>
    </clipPath>
  </defs>
</svg>
      </SvgIcon>
    );
  };
  
  export default YoutubeIcon;