import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
const SpotifyIcon = (props) => {
    return (
      <SvgIcon {...props} viewBox="0 0 56 56">
        {/* Your SVG path goes here */}
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
  <g clip-path="url(#clip0_455_68)">
    <path d="M23 0C10.2975 0 0 10.2977 0 23C0 35.7032 10.2977 46 23 46C35.7034 46 46 35.7032 46 23C46 10.2986 35.7034 0.00125781 22.9996 0.00125781L23 0ZM33.5475 33.1728C33.1355 33.8485 32.251 34.0626 31.5754 33.6479C26.1753 30.3494 19.3773 29.6023 11.3713 31.4315C11.0006 31.5158 10.6115 31.4495 10.2897 31.2471C9.96784 31.0447 9.73953 30.7228 9.65497 30.3521C9.61283 30.1686 9.60731 29.9786 9.63871 29.7929C9.67011 29.6073 9.73782 29.4296 9.83796 29.2702C9.93811 29.1107 10.0687 28.9726 10.2223 28.8637C10.3759 28.7548 10.5495 28.6773 10.7331 28.6355C19.4947 26.6338 27.0097 27.4956 33.0724 31.2008C33.748 31.6155 33.9622 32.4972 33.5475 33.1728ZM36.3626 26.9102C35.8433 27.7538 34.7393 28.0203 33.8963 27.5012C27.7139 23.7011 18.2899 22.6006 10.9775 24.8202C10.0291 25.1067 9.0275 24.5723 8.73964 23.6255C8.45394 22.6771 8.98869 21.6773 9.93546 21.3891C18.2882 18.8544 28.6724 20.0822 35.7722 24.445C36.6153 24.9643 36.8818 26.0682 36.3626 26.9102ZM36.6043 20.3888C29.1915 15.9857 16.9614 15.5807 9.88407 17.729C8.74755 18.0737 7.54562 17.432 7.20134 16.2955C6.85687 15.1584 7.49782 13.9574 8.63524 13.6119C16.7595 11.1455 30.2655 11.6222 38.7997 16.6887C39.8243 17.2953 40.1593 18.6156 39.5523 19.6364C38.9482 20.6589 37.6242 20.9958 36.6054 20.3888H36.6043Z" fill="#1ED760"/>
  </g>
  <defs>
    <clipPath id="clip0_455_68">
      <rect width="46" height="46" fill="white"/>
    </clipPath>
  </defs>
</svg>
      </SvgIcon>
    );
  };
  
  export default SpotifyIcon;